@import "../../styles/variables.scss";

$headerBorderWidth: 3px;

.container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $otcDarkGray;
    height: 70px;

    @include sm-md {
        display: block;
        text-align: center;
        color: #fff;
        line-height: 1.8em;
        padding: 0.5em 0px;
    }

    .linkItem {
        display: inline-block;
        line-height: 10px;
        border-left: 1px solid $otcWhite;
        padding: 0 0.5em;
        color: white;
        font-size: 12px;
        font-weight: 700;

        &:first-child {
            border: none;
        }
    }
}

@import "../../styles/variables.scss";

.container {
  width: 100%;

  .header {
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-gap: 50px;
    
    @media (min-width: $max-width) {
        grid-gap: 100px;
    }

    .main {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex: 1 1 100%;

      @include sm-md {
        display: block;
      }
    }

    @include sm-md {
      display: block;
      margin-bottom: $XLargeSpace;
    }
  }

  .info {
    border-top: $dividerMedium;
    border-bottom: $dividerMedium;
    padding: 1em 0;

    @include accentBorderColor;

    p {
      margin: 0;
      font-family: $otcFontSecondary;
    }
  }

  .dateBox {
    width: 300px;
    margin-top: 8px;

    @include accentBorderColor;

    @media (max-width: $max-width) {
      width: 200px;
    }

    @include sm-md {
      width: auto;
    }
  }

  .dataContainer {
    display: flex;

    @include sm-md {
      display: block;
    }

    .main {
      display: flex;
      flex-direction: column;
      flex-shrink: inherit;
      flex: 1 1 100%;
    }

    .side {
      flex: 0 0 300px;
      flex-direction: column;
      max-width: 300px;
      margin-left: 50px;

      @media (min-width: $max-width) {
        margin-left: 100px;
      }

      @include sm-md {
        max-width: none;
        margin-left: 0;
        margin-top: $XLargeSpace;
      }
    }

    .summaryDesktop {
      display: block;

      @include sm-md {
        display: none;
      }
    }

    .summaryMobile {
      display: none;

      @include sm-md {
        display: block;
      }
    }
  }
}

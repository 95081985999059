@import './global.scss';

// custom devexpress

$fieldBorderColor: #ddd;

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otc-theme {
  .maxHeight {
    max-height: 34px;
  }

  .green {
    .dx-dropdowneditor-icon {
      color: $otcGreen;
    }
  }

  .orange {
    .dx-dropdowneditor-icon {
      color: $otcOrange;
    }
  }

  .pink {
    .dx-dropdowneditor-icon {
      color: $otcPink;
    }
  }
  
  .gray {
    .dx-dropdowneditor-icon {
      color: $otcGray;
    }
  }

  .black {
    .dx-dropdowneditor-icon {
      color: $otcBlack;
    }
  }

  .dx-header-row {
    color: $otcMediumGray;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: $fontWeightBold;
  }
  
  .dx-datagrid .dx-column-lines > td {
    border: none;
    border-bottom: 1px solid $otcLightGray;
  }

  .phone-select {
    &.hasCountryCode {
      .dx-texteditor-input {
        padding-left: 5px
      }
      
      .dx-selectbox.dx-textbox.dx-texteditor.dx-editor-outlined {
        border-left: 1px solid #ddd;

        &.dx-state-hover {
          @include borderGreen();
        }

        &.dx-state-active {
         border-color: $otcGreen;
        }
      }

      .dx-textbox.dx-texteditor.dx-editor-outlined {
        border-left: none;
      }
    }

    .dx-dropdowneditor.dx-dropdowneditor-field-clickable {
      // border-right: none;
      border-left: 1px solid #ddd;
    }

    .dx-dropdowneditor-button.dx-button-mode-contained, .dx-dropdowneditor-button.dx-button-mode-outlined, .dx-dropdowneditor-button.dx-button-mode-text {
      background-color: #f5f5f5;
      width: 15px;
      min-width: 0;
      margin-left: -5px;
    }

    .dx-dropdowneditor-icon {
      width: auto;
    }
  }

  .small {
    .dx-checkbox {
      width: 100%;
    }

    .dx-checkbox-icon {
      width: 18px;
      height: 18px;
    }

    .dx-checkbox-has-text .dx-checkbox-text {
      vertical-align: middle;
      font-size: 13px;
      font-weight: $fontWeightRegular;
      line-height: 16px;
      padding-left: 30px;
    }    
  }

  .dx-checkbox-icon {
    border-color: $otcGray;
  }

  .dx-checkbox-text {
    @include h3;
    padding-left: $XLargeSpace;
    letter-spacing: -.05px;
  }

  .dx-checkbox-has-text .dx-checkbox-text {
    vertical-align: top;
    line-height: $lineHeight;
  }

  .dx-show-clear-button {
    .dx-texteditor-buttons-container {
      align-items: flex-start;
    }

    .dx-clear-button-area {
      height: 30px;
    }
  }

  .dx-icon-clear:before {
    content: "\f00a";
  }
  // TODO FIX SELECTED HIGHLIGHT FROM BLUE
  .dx-list {
    background-color: $otcWhite;
    border: 1px solid $otcLightGray;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.4);
  }

  .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused.dx-list-item-selected {
    background-color: red;
    color: #fff;
  }
  
  .dx-radio-value-container {
    vertical-align: top;
  }

  .dx-radiogroup .dx-radiobutton {
    font-size: 16px;
    font-weight: $fontWeightBold;
    margin: 0 0 $largeSpace;
  }

  .dx-radiobutton-icon:before {
    border-radius: 0;
  }

  .dx-radiobutton-icon-checked .dx-radiobutton-icon-dot {
    border-radius: 0;
    margin-top: -18px;
    margin-left: 4px;
    width: 14px;
    height: 14px;
  }

  .dx-radiobutton.dx-state-hover .dx-radiobutton-icon:before {
    border-color: rgba(51,122,183,.4);
  }
  
  .dx-button-mode-outlined.dx-buttongroup-last-item.dx-button {
    border-radius: 0;
    font-size: 16px;
  }

  .dx-dropdownbutton {
    width: 100%;
  }

  .dx-dropdownbutton-action.dx-button .dx-button-content {
    padding: $smallSpace $mediumSpace;
    text-align: left;
  }

  .dx-button-has-text .dx-icon.dx-icon-right {
    font-size: 40px;
    float: right;
  }
  .dx-dropdowneditor-button.dx-state-active .dx-dropdowneditor-icon,
  .dx-dropdowneditor.dx-dropdowneditor-active .dx-dropdowneditor-icon,
  .dx-dropdowneditor.dx-state-active .dx-dropdowneditor-icon,
  .dx-dropdowneditor.dx-state-hover:not(.dx-custom-button-hovered) .dx-dropdowneditor-icon {
    background-color: transparent;
    border-color: transparent;

    &:before {
      color: $otcGreen;
    }
  }

  .dx-texteditor.dx-state-hover,
  .dx-texteditor.dx-state-hover.dx-editor-filled,
  .dx-radiobutton.dx-state-hover .dx-radiobutton-icon:before {
    @include borderGreen;
  }

  .dx-texteditor.dx-state-active.dx-editor-filled,
  .dx-texteditor.dx-state-active.dx-editor-outlined,
  .dx-texteditor.dx-state-active.dx-editor-underlined,
  .dx-texteditor.dx-state-focused.dx-editor-filled,
  .dx-texteditor.dx-state-focused.dx-editor-outlined,
  .dx-texteditor.dx-state-focused.dx-editor-underlined {
    border-color: $otcGreen;
  }

  .dx-texteditor.dx-editor-outlined, .dx-texteditor-input {
    border-radius: 0;
  }

  .dx-show-clear-button .dx-icon-clear {
    color: $otcGreen;
  }

  .phone-container {
    display: flex;

    .dx-selectbox-container {
      background-color: $otcLightGray;
    }

    .dx-dropdowneditor-button {
      min-width: 0;
      width: auto;
      padding-right: 8px;
    }

    .dx-dropdowneditor-icon {
      width: 8px;
    }

    .dx-texteditor-input-container {
      font-size: 12px;
    }
    
    .dx-dropdowneditor-input-wrapper .dx-texteditor-input {
      font-weight: $fontWeightSemiBold;
    }
  }

  .dx-texteditor.dx-state-readonly{
    border-style: solid;
  }

  .dx-scrollable-native.dx-scrollable-vertical, .dx-scrollable-native.dx-scrollable-vertical .dx-scrollable-container {
    overflow-y: overlay;
  }

  // ERRORS
  .dx-invalid.dx-texteditor {
    border: 1px solid $fieldBorderColor;
    @include notValid;
  }

  .dx-invalid.dx-texteditor.dx-editor-filled.dx-show-invalid-badge .dx-texteditor-input-container:after, .dx-invalid.dx-texteditor.dx-editor-outlined.dx-show-invalid-badge .dx-texteditor-input-container:after, .dx-invalid.dx-texteditor.dx-editor-underlined.dx-show-invalid-badge .dx-texteditor-input-container:after {
    content: initial;
  }

  .dx-invalid.dx-texteditor.dx-editor-filled.dx-state-focused,
  .dx-invalid.dx-texteditor.dx-editor-outlined.dx-state-focused,
  .dx-invalid.dx-texteditor.dx-editor-underlined.dx-state-focused {
    border-color: $otcGreen;
    border-bottom-color: $otcErrorColor;
  }
  
  .dx-button-mode-contained.dx-button-default,
  .dx-button-mode-contained.dx-button-default.dx-state-hover,
  .dx-button-mode-contained.dx-button-default.dx-state-active {
    background: none;
  }

  // phone input
  .react-tel-input .form-control {
    border: 1px solid $fieldBorderColor;
    border-radius: 0;
    width: 100%;

    &[disabled] {
      border: 1px solid $otcLightGray;
      color: $otcLightGray;
    }
  }

  .react-tel-input .flag-dropdown {
    border-color: $fieldBorderColor;
  }
}

.dx-calendar-views-wrapper {
  .dx-calendar-cell.dx-calendar-empty-cell {
    background: none;
  }
}

.dx-popup-title  {
  border-bottom: none !important;
}

.dx-popup-wrapper > .dx-overlay-content {
  border-color: $otcGreen !important;
}
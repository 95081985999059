@import '../../styles/global.scss';

.container {
  position: relative;
}

.tooltip {
  position: absolute;
  display: block;
  background: $otcWhite;
  border: 1px solid $otcGray;
  min-width: 120px;
  padding: 5px;

  &.green {
    border-color: $otcGreen;

    .triangle {
      border-color: $otcGreen;
    }
  }

  &.orange {
    border-color: $otcOrange;

    .triangle {
      border-color: $otcOrange;
    }
  }

  &.pink {
    border-color: $otcPink;

    .triangle {
      border-color: $otcPink;
    }
  }

  &.gray {
    border-color: $otcGray;

    .triangle {
      border-color: $otcGray;
    }
  }

  &.black {
    border-color: #000;

    .triangle {
      border-color: #000;
    }
  }

  .title {
    font-weight: bold;
    font-size: 11px;
  }

  .text {
    font-size: 11px;
  }

  .triangle {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    background: white;
    border-left: 1px solid $otcGray;
    border-top: 1px solid $otcGray;
    left: 50%;
    transform: translateX(-50%) rotate(-135deg);
    bottom: -5px;
  }
}

.state {
  stroke-width: 1px;

  &.static {
    stroke: $otcWhite;
    fill: $otcGray;
  }

  &.green {
    stroke: $otcGreen;
    fill: $otcWhite;

    &.compliance {
      stroke: $otcWhite;
      fill: $otcGreen;
    }
  }

  &.orange {
    stroke: $otcOrange;
    fill: $otcWhite;

    &.compliance {
      stroke: $otcWhite;
      fill: $otcOrange;
    }
  }

  &.pink {
    stroke: $otcPink;
    fill: $otcWhite;

    &.compliance {
      stroke: $otcWhite;
      fill: $otcPink;
    }
  }

  &.gray {
    stroke: $otcGray;
    fill: $otcWhite;

    &.compliance {
      stroke: $otcWhite;
      fill: $otcGray;
    }
  }

  &.black {
    stroke: #000;
    fill: $otcWhite;

    &.compliance {
      stroke: $otcWhite;
      fill: #000;
    }
  }
}
@import '../../styles/global.scss';

.topBar {
  display: flex;

  @include lg {
    flex-wrap: wrap;
    &.hasSidebar {
      flex-wrap: nowrap;
    }
  }
  @include sm-md {
    flex-wrap: wrap;
    margin-bottom: $largeSpace;
  }
}

.dxSelect {
  width: 100%;
  margin-top: $smallSpace;
  border: none !important;
  border-bottom: 1px solid $otcLightGray !important;
  
  input {
    font-size: 16px;
    padding-left: 0;
  }
}


.selectContainer {
  border-top: $dividerMedium;

  .dxSelect {
    &.dx-selectbox {
      width: 100%;
    }
    
    &.dx-texteditor.dx-editor-outlined {
      border: none;
    }
  }

  @include lg {
    flex: 0 0 $sidebarWidth;
    margin-right: $sidebarMargin;
    a {
      margin: 0;
      margin-top: -14px;
      padding: .7em 0;
      height: auto;
    }
  }
  @include sm-md {
    width: 100%;
    a {
      margin-top: -5px;
    }
  }
}

.rulesBlock {
  display: flex;

  @include sm-md {
    display: block;
    margin-bottom: $mediumSpace;
  }
}

.mainContent {
  @include lg {
    padding-top: .75rem;
    border-top: 1px solid $otcGreen;
  }
  @include sm-md {
    padding-top: .5rem;
    padding-bottom: 1rem;
  }
}

.fullWidthContent {
  @include lg {
    border-top-width: 3px;
  }
}

.sectionTitle {
  flex: 0 0 300px;
  flex-direction: column;
  max-width: 300px;
  color: $otcGreen;
  margin-right: 100px;
  text-transform: uppercase;
  font-size: .9em;
  font-weight: bold;
  padding-top: .7rem;
  border-top: 1px solid $otcGreen;

  @include lg {
    font-size: .95em;
    border-top: 3px solid $otcGreen;
  }
  
  @include sm-md {
    max-width: none;
    margin-right: 0;
    border: none;
  }
  > div {
    margin-bottom: .6rem;
  }
}

.section {
  padding-bottom: 4.3rem;

  @include sm-md {
    border-top: 1px solid $otcGreen;
    padding-bottom: .5rem;

    &:first-child {
      border-top-width: 3px;
    }
  }
}

.color-orange { color: $otcOrange; }
.color-pink { color: $otcPink; }

.border-orange { border-color: $otcOrange; }
.border-pink { border-color: $otcPink; }
.border-gray {
  border-color: $otcLightGray !important;
  border-width: 1px;
}
.border-none {
  border: none !important;
}

.menuHolder {
  flex: 1 1 100%;
  min-width: 0;
}

.selectBox {
  position: relative;
  width: 100%;
  @include lg {
    bottom: .4em;
  }
}

.Red {
  color: $otcWarningColor;
}

.jumpLinks {
  .linkItem {
    float: left;
    font-size: .9em;
    color: $otcGray;
    cursor: pointer;
    text-decoration: none;
    margin: 0 16px 8px 0;

    &:hover {
      color: $otcGreen
    }

    &.boldLink {
      font-weight: bold;
    }

    &.lastLine {
      margin-bottom: $mediumSpace;
    }
    
    &.newLine {
      display: block;
      clear: both;
    }
  }
}

ol {
  &.noPadding {
    padding: 0 0 0 15px;
  }

  ol {
    margin: 1em 0;
  }
}

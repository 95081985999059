@import "../../styles/variables.scss";

.link {
    display: flex;
    justify-content: space-between;
    border: 1px solid $otcGreen;
    padding: $smallSpace;
    color: $otcDarkGray;

    &.green {
        border-color: $otcGreen;

        &:hover {
            color: $otcGreen;
        }
    }

    &.orange {
        border-color: $otcOrange;

        &:hover {
            color: $otcOrange;
        }
    }

    &.pink {
        border-color: $otcPink;

        &:hover {
            color: $otcPink;
        }
    }

    &.gray {
        border-color: $otcGray;

        &:hover {
            color: $otcGray;
        }
    }

    &.black {
        border-color: #000;

        &:hover {
            color: #000;
        }
    }
}
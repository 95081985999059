@import "../../styles/variables.scss";

.container {
    width: 100%;

    .content {
        display: flex;

        @include sm-md {
            display: block;
        }

        .main {
            flex: 1 1 100%;
        }

        .search {
            flex: 0 0 300px;
            flex-direction: column;
            margin-right: 100px;

            @include lg {
                flex: 0 0 200px;
                margin-right: 50px;
            }
            @media (min-width: $max-width) {
                flex: 0 0 300px;
                margin-right: 100px;
            }

            @include sm-md {
                margin-right: 0;
                margin-bottom: $largeSpace;
                max-width: none;
            }
        }
    }

    a {
        text-decoration: underline;
        color: inherit;
    }

    .download {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
    }
}
@import '../../styles/variables.scss';

.container {
  display: inline-block;
  font-size: 16px;
  color: $otcGrey;
  cursor: pointer;
  margin-bottom: $largeSpace;

  span {
    margin-left: $smallSpace;
  }
}
@import "../../styles/variables.scss";

.container {
    display: flex;
    justify-content: space-between;
    padding: 0.5em 0;
    border-bottom: 1px solid $otcLightGray;

    .title {
        font-weight: $fontWeightBold;
    }
}

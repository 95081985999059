@import "../../styles/variables.scss";

$headerBorderWidth: 3px;

.container {
    display: block;
    position: relative;
    background-color: $otcDarkGray;
    height: 70px;

    .content {
        display: flex;
        align-items: center;
        height: 100%;
        max-width: $max-width;
        margin: 0 auto;
        padding: 0 $mediumSpace;

        img {
            height: 25px;
        }
    }

    .otcLine {
        width: 100%;
        height: $headerBorderWidth;
        background-color: $otcOrange;
        position: absolute;
        bottom: 0;
        overflow: hidden;

        &:after {
            content: '';
            background: $otcGreen;
            position: absolute;
            width: 33.33%;
            left: 0;
            bottom: 0;
            height: $headerBorderWidth;
            transform: skew(-24deg,0);
        }
        &:before {
            content: '';
            background: $otcPink;
            position: absolute;
            width: 33.33%;
            right: 0;
            bottom: 0;
            height: $headerBorderWidth;
            transform: skew(-24deg,0);
        }
    }
}
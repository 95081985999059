@import '../../styles/global.scss';

.component {
  position: relative;
  margin-top: .85em;
  font-weight: $fontWeightBold;
  text-align: right;
  color: $otcDarkGray;
  font-size: 0.8rem;

  @include sm {
    position: relative;
    text-align: center;
  }
}